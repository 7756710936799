import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import ymaps from 'yandex-maps';

import StringList from './StringList';

const Map: React.FC = () => {
    const mapRef: React.RefObject<HTMLDivElement> = React.createRef();

    const myMap = useRef<ymaps.Map | undefined>();
    const route = useRef<ymaps.multiRouter.MultiRoute | undefined>();

    const [points, setPoints] = useState<string[]>([]);
    const [distance, setDistance] = useState<string>("0 км");

    const exportGPX = () => {
        const activeRoute = route.current!.getActiveRoute();
        if (!activeRoute) {
            alert('Сначала надо построить маршрут');
            return;
        }

        const paths = activeRoute.getPaths();
        const coordinates: number[][] = [];

        for (let i = 0; i < paths.getLength(); i++) {
            coordinates.push(...paths.get(i).properties.get('coordinates', []) as number[][]);
        }

        const gpxData = generateGPX(coordinates);

        const element = document.createElement('a');
        element.href = `data:text/xml;charset=utf-8,${encodeURIComponent(gpxData)}`;
        element.download = 'route.gpx';
        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    const generateGPX = (coordinates: number[][]): string => {
        let gpx = `<?xml version="1.0" encoding="UTF-8" standalone="no" ?>` +
            `<gpx xmlns="http://www.topografix.com/GPX/1/1" version="1.1" creator="OpenAI ChatGPT">` +
            `<trk><trkseg>`;

        for (let i = 0; i < coordinates.length; i++) {
            const [lat, lon] = coordinates[i];
            gpx += `<trkpt lat="${lat}" lon="${lon}"></trkpt>`;
        }

        gpx += `</trkseg></trk></gpx>`;
        return gpx;
    };

    useEffect(() => {
        if (myMap.current !== undefined) return;

        var buttonSaver = new ymaps.control.Button({
            data: { content: "Экспорт GPX" },
            options: { selectOnClick: false, maxWidth: 300 },
        });
        buttonSaver.events.add("click", exportGPX);

        var buttonEditor = new ymaps.control.Button({
            data: { content: "Режим редактирования" },
            options: { selectOnClick: true, maxWidth: 300 },
        });
        buttonEditor.events.add("select", function () {
            /**
             * Включение режима редактирования.
             * В качестве опций может быть передан объект с полями:
             * addWayPoints - разрешает добавление новых путевых точек при клике на карту. Значение по умолчанию: false.
             * dragWayPoints - разрешает перетаскивание уже существующих путевых точек. Значение по умолчанию: true.
             * removeWayPoints - разрешает удаление путевых точек при двойном клике по ним. Значение по умолчанию: false.
             * dragViaPoints - разрешает перетаскивание уже существующих транзитных точек. Значение по умолчанию: true.
             * removeViaPoints - разрешает удаление транзитных точек при двойном клике по ним. Значение по умолчанию: true.
             * addMidPoints - разрешает добавление промежуточных транзитных или путевых точек посредством перетаскивания маркера, появляющегося при наведении курсора мыши на активный маршрут. Тип добавляемых точек задается опцией midPointsType. Значение по умолчанию: true
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/multiRouter.MultiRoute.xml#editor
            */
            route.current!.editor.start({
                addWayPoints: true,
                removeWayPoints: true,
                addMidPoints: true,
                editorDrawOver: false,
                editorMidPointsType: "via",
            });
        });
        buttonEditor.events.add("deselect", function () {
            // Выключение режима редактирования.
            route.current!.editor.stop();
        });

        route.current = new ymaps.multiRouter.MultiRoute({
            referencePoints: [],
            params: {
                //Тип маршрутизации - пешеходная маршрутизация.
                routingMode: 'pedestrian',
                reverseGeocoding: true,
                searchCoordOrder: "latlong",
            }
        }, {
            // Автоматически устанавливать границы карты так, чтобы маршрут был виден целиком.
            boundsAutoApply: true,
        });

        route.current.events.add('update', function () {
            let newPoints: string[] = [];

            (route.current?.properties.get("waypoints", []) as any[]).forEach((wayPoint) => newPoints.push(wayPoint));

            setPoints(newPoints);

            const activeRoute = route.current?.getActiveRoute();
            if (!activeRoute)
                return;

            interface DistanceResponse {
                value: number,
                text: string,
            }

            setDistance((activeRoute.properties.get("distance", { text: '' }) as DistanceResponse).text);
        });

        myMap.current = new ymaps.Map(mapRef.current!, {
            center: [55.753994, 37.622093],
            zoom: 12,
            controls: [buttonSaver, buttonEditor],
        });

        // Добавляем мультимаршрут на карту.
        myMap.current.geoObjects.add(route.current);
    });

    const onListChange = (points: string[]) => {
        const normalizedPoints = points.map((s: any) => {
            if (typeof s === 'string')
                return s;
            else if (typeof s.request !== 'string')
                return s.request;
            else
                return s.request;
        });

        // console.log("normalizedPoints:", normalizedPoints);
        route.current?.model.setReferencePoints(normalizedPoints);
    };

    const listMapper = (item: any, i: number) => {
        let output;

        if (typeof item === 'string')
            output = item;
        else if (typeof item.request === 'string')
            output = item.request
        else
            output = JSON.stringify(item.request);

        // console.log("item map:", item, "to:", output);

        return output;
    };

    return (
        <Row>
            <Col xl="9" xs="12">
                <div id="map" ref={mapRef}></div>
            </Col>
            <Col xl="3" xs="12">
                <StringList values={points} onApplyChanges={onListChange} mapper={listMapper}></StringList>
                <h2>Дистанция</h2>
                {distance}
            </Col>
        </Row>
    );
};

export default Map;
