module.exports = new Promise((resolve) => {
                        if (typeof ymaps !== 'undefined') {
                          return ymaps.ready(() => resolve(ymaps));
                        }
              
                        const script = document.createElement('script');
                        script.src = "https://api-maps.yandex.ru/2.1/?apikey=27c7c5fe-4e44-44f6-b30e-ea208edddb94&lang=ru-RU";
                        script.onload = () => {
                          ymaps.ready(() => resolve(ymaps));
                        };
                        document.body.appendChild(script);
                      });