import React, { useState, useEffect } from 'react';
import { Button, ListGroup, ListGroupItem, Form, Row, Col } from 'react-bootstrap';

interface StringListProps {
    values: any[];
    onApplyChanges: (newValues: any[]) => void;
    mapper: (value: object, index: number) => string;
}

const StringList: React.FC<StringListProps> = ({ values, onApplyChanges, mapper }) => {
    const [editedValues, setEditedValues] = useState(values);
    const [hasChanges, setHasChanges] = useState(false);

    useEffect(() => {
        setEditedValues(values);
    }, [values]);

    const handleTextChange = (index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const newValues = [...editedValues];
        newValues[index] = event.target.value;
        setEditedValues(newValues);
        setHasChanges(true);
    };

    const handleDelete = (index: number) => {
        const newValues = [...editedValues];
        newValues.splice(index, 1);
        setEditedValues(newValues);
        setHasChanges(true);
    };

    const handleMoveUp = (index: number) => {
        if (index > 0) {
            const newValues = [...editedValues];
            [newValues[index - 1], newValues[index]] = [newValues[index], newValues[index - 1]];
            setEditedValues(newValues);
            setHasChanges(true);
        }
    };

    const handleMoveDown = (index: number) => {
        if (index < editedValues.length - 1) {
            const newValues = [...editedValues];
            [newValues[index], newValues[index + 1]] = [newValues[index + 1], newValues[index]];
            setEditedValues(newValues);
            setHasChanges(true);
        }
    };

    const handleApplyChanges = () => {
        onApplyChanges(editedValues);
        setHasChanges(false);
    };

    const handleAdd = () => {
        setEditedValues([...editedValues, '']);
        setHasChanges(true);
    };

    return (
        <div>
            <ListGroup>
                <h2>Список точек</h2>
                {editedValues.map((value, index) => (
                    <ListGroupItem key={index}>
                        <div className="d-flex justify-content-between">
                                <Col xs="9">
                                    <Form.Control
                                        type="text"
                                        value={mapper(value, index)}
                                        onChange={(event) => handleTextChange(index, event)}
                                    />
                                </Col>
                                <Col xs="1">
                                    {index !== 0 && <Button variant="outline-primary" size="sm" onClick={() => handleMoveUp(index)}>
                                        ↑
                                    </Button>}</Col>
                                <Col xs="1">{index !== editedValues.length - 1 && <Button variant="outline-primary" size="sm" onClick={() => handleMoveDown(index)}>
                                    ↓
                                </Button>}</Col>
                                <Col xs="1"><Button variant="outline-danger" size="sm" onClick={() => handleDelete(index)}>
                                    X
                                </Button></Col>
                        </div>
                    </ListGroupItem>
                ))}
            </ListGroup>
            <Button variant="success" onClick={handleAdd}>
                Добавить
            </Button>
            {' '}
            <Button variant="primary" onClick={handleApplyChanges} disabled={!hasChanges}>
                Применить изменения
            </Button>
        </div>
    );
};

export default StringList;
